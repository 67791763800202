@charset "utf-8";
@use "src/styles/bootstrap";
@use "sass:map";

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// hide some image editor save elements
.fjFruI,
.fdFVUf {
  display: none !important;
}

// change save button
.sc-21g986-5 {
  margin-left: 70px;
}

.jIgSWS {
  text-indent: -9999px;
  line-height: 0;

  &:after {
    content: "Зберегти";
    text-indent: 0;
    display: block;
    line-height: initial;
  }
}

.ant-input, .ant-select-selection-search-input{
  font-size: 16px;
}
