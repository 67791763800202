@use "src/styles/bootstrap";
@use "sass:map";

.input {
  min-width: 600px;

  :global {
    .ant-select-arrow {
      pointer-events: auto;
      user-select: auto;
    }
  }

  .search {
    margin-right: -15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .fileInfo {
    width: calc(100% - 10px - 150px);
    overflow: hidden;

    svg {
      font-size: 30px;
      fill: rgba(0, 0, 0, 0.6);
    }
  }

  .fileName {
    width: 100%;
    word-break: break-word;
    white-space: normal;
  }

  img {
    width: auto;
    max-width: 150px;
    height: 100px;
    object-fit: contain;
  }
}

.searchSwitch {
  transform: rotate(-90deg);
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "phone")) {
  :global {
    .ant-select-dropdown {
      width: 300px !important;
    }
  }

  .input {
    min-width: auto;
    width: 200px;
  }

  .file {

    .fileInfo {
      width: 100%;

      svg {
        font-size: 12px;
      }
    }

    .fileName {
      font-size: 12px;
      width: 100%;
      word-break: break-word;
      white-space: normal;
    }

    img {
      width: auto;
      max-width: 100px;
      height: 50px;
    }
  }

  .switchLabel {
    span {
      font-size: 12px;
    }
  }
}
