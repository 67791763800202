@use "src/styles/bootstrap";
@use "sass:map";

.container {
  padding: 10px;
}

.centered {
  .meta {
    justify-content: center;
  }
}

.meta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0;

  img {
    max-width: 100%;
  }

  h2 {
    text-align: center;
    font-size: 12px;
    color: rgba(0, 0, 0, .7)
  }

  .phones {
    display: flex;
    flex-direction: column;

    .phone {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "tablet")) {
  .meta {

    img {
      max-width: 80%;
      margin-bottom: 10px
    }

    h2 {
      text-align: center;
      font-size: 14px;
    }

    .phones {
      width: 100%;
      justify-content: center;
      flex-direction: row;

      .phone {
        font-size: 12px;
      }
    }
  }
}
