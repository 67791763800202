.files {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.loaderContainer {
  position: absolute;
  left: -10px;
  width: 100vw;
  min-height: 300px;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.3);
}
