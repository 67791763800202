@use "src/styles/bootstrap";
@use "sass:map";

.sort {
  max-width: 150px;
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "phone")) {
  .actions {
    flex-wrap: wrap;

    :global {
      .anticon {
        display: none;
      }

      button {
        padding-right: 7px;
        padding-left: 7px;
      }

      span {
        margin: 0 !important;
      }
    }
  }

  .sort {
    max-width: 120px;
  }
}
