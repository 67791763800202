@use "src/styles/bootstrap";
@use "sass:map";

.container {
  position: relative;
  height: 100vh;
}

.carouselContainer {
  width: 100%;
  height: 100%;
}

.carouselItem {
  height: 100vh;
  width: 100vw;
}

.formContainer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  background: rgba(#ffffff, .9);
}

.form {
  width: 70%;
  padding: 10px;

  .title {
    text-align: center;
    padding-bottom: 10px;
  }

  .errorMessage {
    margin-bottom: 24px;
  }

  .submit {
    left: 50%;
    transform: translateX(-50%);
  }

  :global {
    .ant-form-item-label > .ant-form-item-required:before {
      content: unset !important;
    }
  }
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "phone")) {
  .formContainer {
    width: 100%;
    border-radius: 0;
  }
}
