@use "src/styles/bootstrap";
@use "sass:map";

:global {
  .ant-layout-header {
    padding-inline: 20px !important;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo {
  height: 30px;
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "phone")) {
  .logo {
    height: 20px;
  }
}
