@use "src/styles/bootstrap";
@use "sass:map";

.file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  height: 220px;
  width: 180px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: #dcdcdc;
    border-radius: 5px;

    .delete {
      display: block;
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  font-size: 100px;
  opacity: 0.7;
}

.preview {
  object-fit: contain;
  height: 170px;
  width: 170px;
  display: block;
  margin: 0 auto;
}

.name {
  height: 70px;
  white-space: normal;
  word-break: break-word;
  text-overflow: ellipsis;
  text-align: center;
}

.delete {
  position: absolute;
  right: -5px;
  top: -5px;
  display: none;
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "phone")) {
  .file {
    width: calc(50% - 7.5px);
  }
}
