.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.zoomButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.zoomSlider {
  width: 400px;
}
